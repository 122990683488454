<template>
  <div class="load-more">
    <slot></slot>
  </div>
</template>

<script>
const delay = (function () {
  let timer = 0
  return function (callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()
export default {
  data() {
    return {
      loadState: true,
      finish: false,
      loading: false,
      domHeight: 0,
      container: null
    }
  },
  mounted() {
    this.container = document.body
    this.domHeight = document.body.clientHeight
    this.bindSrcoll()
  },
  methods: {
    scrollPage() {
      delay(() => {
        if (!document.body) {
          return
        }
        var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
        var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollHeight - windowHeight - scrollTop < 450) {
          this.$emit('loadMore')
        }
      }, 200)
    },
    switchBottom() {
      var that = this;
      this.$nextTick(() => {
        if (that.$el.scrollHeight > that.domHeight) {
          that.showState = true
        } else {
          if (!that.finish) {
            that.showState = true
            setTimeout(() => {
              that.$emit('loadMore')
            }, 1000)
          } else {
            that.showState = false
          }
        }
      })
    },
    bindSrcoll() {
      this.unScroll()
      window.addEventListener('scroll', this.scrollPage.bind(this, self))
    },
    unScroll() {
      window.removeEventListener('scroll', this.scrollPage)
    }
  },
  beforeDestroy() {
    this.unScroll()
  }
}
</script>

<style>
</style>
